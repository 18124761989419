@import 'variables'

.law_case
  position: relative
  border: $card-border-width solid $card-border-color
  border-bottom: none
  padding: 1.4rem 1.4rem 1.6rem
  h3
    font-size: 1.6rem
    padding: 0.4rem 0
  &:last-of-type
    border-bottom: $card-border-width solid $card-border-color
