@import "~bootstrap/scss/_functions"
@import "~bootstrap/scss/_variables"

$theme-colors: ( "primary": #084887, "secondary": #6c757d, "success": #28a745, "info": #17a2b8, "warning": #ffc107, "danger": #dc3545, "light": #f8f9fa, "dark": #265fa0 )

$colors: ( "blue": #084887, "indigo": #6610f2, "purple": #6f42c1, "pink": #e83e8c, "red": #dc3545, "orange": #fd7e14, "yellow": #ffc107, "green": #28a745, "teal": #20c997, "cyan": #17a2b8, "white": #fff, "gray": #adbbcc, "gray-dark": #495563 )

$headings-margin-bottom: 1rem

$link-color: $info

$btn-primary-bg: darken($primary, 20%)

$breadcrumb-divider: quote(">")

$font-family-sans-serif: 'Oswald', sans-serif
$font-family-serif: 'EB Garamond', serif
$font-family-base: $font-family-serif
$headings-font-family: $font-family-sans-serif
$font-size-base: 1.2rem

$pagination-color: $info
$pagination-active-color: #fff
$pagination-active-bg: $info
$pagination-active-border-color: $info

$sizes: map-merge((10: 10%,20: 20%,30: 30%,40: 40%,50: 50%,60: 60%,70: 70%,80: 80%,90: 90%),$sizes)
