@import "variables"

body
  min-height: 100vh
  position: relative
  padding-bottom: 60px

ul.pagination, .sort-links
  padding: 0.6rem 0 0.4rem

.paginate-and-sorting
  display: block
  overflow: hidden

header
  line-height: 1
  border-bottom: 1px solid #e5e5e5

footer
  position: absolute
  bottom: 0
  width: 100%
  height: 120px
  line-height: 60px
  background-color: #f5f5f5

  p
    margin-bottom: 0

.home_search
  padding: 0 0.75rem

.jumbotron
  background-color: map-get($theme-colors, 'primary')
  a
    color: map-get($theme-colors, 'info')
a
  color: map-get($theme-colors, 'info')
.nav a
  color: $body-color !important
