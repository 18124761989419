@import 'variables'

select.form-control
  font-size: 1.1rem
  padding: 0.375rem 0.5rem

.filters
  transition: height 0.6s
  .show_button
    display: none
  &.hidden
    position: relative
    height: 120px
    overflow: hidden
    .show_button
      width: 100%
      padding: 0.8rem 0
      background: linear-gradient(transparent 0%, rgba(#97c2ed, 0.5) 50%)
      cursor: pointer
      position: absolute
      bottom: 0px
      left: 0px
      right: 0px
      margin: 0 auto
      display: block
      text-align: center
      span
        background: map-get($theme-colors, 'primary')
        color: #fff
        padding: 0.4rem 0.6rem
        border-radius: 0.2rem