@import 'variables'

.nav
  font-weight: 700

.navbar-brand
  // color: darken($primary, 12%)

#main-container
  padding-bottom: 60px

.page-item.active
  .page-link
    color: #fff !important