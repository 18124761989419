@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "~select2/dist/css/select2";
@import "~@ttskch/select2-bootstrap4-theme/src/select2-bootstrap4";

.select2-container--bootstrap4 .select2-selection--multiple .select2-selection__choice {
  padding: 4px 8px 4px 8px !important;
  margin-top: -0.45rem;
}
.select2 {
  .select2-selection {
    font-size: 1.1rem;
    &.select2-selection--single {
      padding: 0.5rem !important;
    }
  }
  ul {
    padding: 0.2rem !important;
    li {
      font-size: 90%;
      margin-top: 0 !important;
      margin: 0.2rem !important;
    }
  }
}