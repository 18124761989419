@import 'variables'

.header-title
  a
    font-weight: 700
    font-size: 1.4em
    font-family: $headings-font-family
    color: theme-color("primary") !important

.subheading
  display: block
  font-size: 0.6em
